import WARNING_CIRCLE_ICON from "assets/icons/warning_circle_red_border.svg";
import DefaultThumbnail from "../DefaultThumbnail";
import NOT_AVAILABLE_ICON from "assets/icons/warning_circle.svg";

import VideoShareBroken from "assets/images/video/videoshare-broken.png";
import VideoShareLoading from "assets/images/video/videoshare-loading.png";
import videojs from "video.js";
import "video.js/dist/video-js.css"; // 기본 video.js 스타일
import * as s from "./ThumbnailStyled";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { getCaptionUrl } from "components/blocks/video/VideoPlayer";
import { debounce, throttle } from "lodash";
export type VideoUploadStatus =
  | "presigned" // 10% loading
  | "complete" // encoding 전이므로 loading
  // | "uploading" // 50% loading
  | "encoding" // 80% loading
  | "vtt" // 90% loading
  | "fail" // broken image
  | "cancel" // broken image
  | "success"; // default

// status closed -> expired image 사용
// sharing OFF false -> expired image 사용

type VideoShareThumbnailProps = {
  isRecorderList?: boolean;
  thumbnailUrl: string | undefined;
  isLazy?: boolean;
  uploadStatus: VideoUploadStatus;
  status: string;
  isShare: boolean;
  videoUrl: string;
  isHoveredThumbnail: boolean;
  onHoverThumbnail: (isHover: boolean) => void;
  index: number;
};

function VideoShareThumbnail(props: VideoShareThumbnailProps): JSX.Element {
  // console.log(props.status, props.uploadStatus, props.isShare);
  const {
    thumbnailUrl,
    uploadStatus,
    videoUrl,
    isHoveredThumbnail,
    onHoverThumbnail,
  } = props;
  // const status = "open";
  // const uploadStatus: VideoUploadStatus = "encoding";
  // const isShare = true;
  const videoRef = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<any>(null);

  const [isVideoLoading, setIsVideoLoading] = useState<boolean>(true);

  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = NOT_AVAILABLE_ICON;
  };

  const handleMouseEnter = useCallback(
    throttle(() => {
      if (!playerRef.current || !isHoveredThumbnail) {
        onHoverThumbnail(true);
      }
    }, 500),
    [isHoveredThumbnail]
  );

  const handleMouseLeave = useCallback(() => {
    onHoverThumbnail(false);
  }, [isHoveredThumbnail]);

  // 비디오 파일 확장자에 따라 MIME 타입 반환
  const getMimeType = (url: string) => {
    // console.log(url);

    if (url.endsWith(".webm")) return "video/webm";
    if (url.endsWith(".ogv")) return "video/ogg";
    if (url.endsWith(".m3u8")) return "application/x-mpegURL";

    return "video/mp4";
  };

  // console.log(videoUrl);
  useEffect(() => {
    if (videoUrl !== undefined && videoRef.current && isHoveredThumbnail) {
      // video.js 플레이어 초기화
      playerRef.current = videojs(videoRef.current, {
        controls: false,
        autoplay: false,
        muted: true,
        preload: "auto",
      });

      // HLS 소스 설정
      playerRef.current.src({
        src: videoUrl,
        type: getMimeType(videoUrl), // m3u8 형식
      });

      // 비디오 로드 시작
      playerRef.current.on("loadstart", () => {
        console.log("loadStart");
        setIsVideoLoading(true);
      });

      // 비디오 로드가 완료된 후 재생
      playerRef.current.on("loadeddata", () => {
        console.log("loadeddata");
        setIsVideoLoading(false);
        if (isHoveredThumbnail) {
          // console.log(playerRef.current, isHoveredThumbnail, "ready 5555");
          playerRef.current?.play().catch((err: any) => {
            console.warn("Video play error:", err);
          });
        }
      });

      // playerRef.current.ready(() => {
      //   console.log(playerRef.current, "ready 5555");
      //   if (isHoveredThumbnail) {
      //     playerRef.current?.play().catch((err: any) => {
      //       console.warn("Video play error:", err);
      //     });
      //   }
      // });

      const trackEl = playerRef.current.addRemoteTextTrack(
        {
          kind: "captions",
          label: "timestamp",
          language: "en",
          src: encodeURI(getCaptionUrl(videoUrl)).replace("#", "%23"),
        },
        false
      );
      trackEl.addEventListener("load", function () {
        var tracks = playerRef.current.textTracks();

        for (var i = 0; i < tracks.length; i++) {
          var track = tracks[i];
          if (track.label === "timestamp") {
            track.mode = "showing";
          }
        }
      });

      // 클린업 처리: 컴포넌트가 언마운트될 때 플레이어 해제
      return () => {
        if (playerRef.current) {
          playerRef.current.dispose();
        }
      };
    }
  }, [videoUrl, isHoveredThumbnail]);

  return (
    <DefaultThumbnail>
      {uploadStatus === "success" ? (
        thumbnailUrl === undefined || thumbnailUrl === "" ? (
          <s.Container className="video-loss-image">
            <img
              src={WARNING_CIRCLE_ICON}
              alt="warning circle icon"
              loading={
                props.isLazy !== undefined && props.isLazy ? "lazy" : "eager"
              }
            />

            <p className="warning-text">Thumbnail Not Available</p>
          </s.Container>
        ) : (
          <s.ImageBg
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
          >
            {/* {(!isShare || status === "closed") && (
              <Container className="video-loss-image">
                <img src={VideoShareNoAccess} alt="video-share-noaccess" />
                <p>ACCESS RESTRICTED</p>
              </Container>
            )} */}

            <div className="black-filter"></div>
            <img
              src={encodeURI(thumbnailUrl).replace("#", "%23")}
              alt="recorder thumbnail"
              onError={imageOnErrorHandler}
              loading="lazy"
            />

            {/* {(!isHoveredThumbnail || isVideoLoading) && (
              <>
                <div className="black-filter"></div>
                <img
                  src={encodeURI(thumbnailUrl).replace("#", "%23")}
                  alt="recorder thumbnail"
                  onError={imageOnErrorHandler}
                  loading="lazy"
                />
              </>
            )}

            {(isHoveredThumbnail || !isVideoLoading) && (
              <div data-vjs-player className="hover-video">
                <video
                  ref={videoRef}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    position: "absolute",
                  }}
                  className="video-js"
                />
              </div>
            )} */}
          </s.ImageBg>
        )
      ) : uploadStatus === "fail" || uploadStatus === "cancel" ? (
        <s.Container>
          <img src={VideoShareBroken} alt="video-share-broken" />
        </s.Container>
      ) : (
        <s.Container>
          <img
            src={VideoShareLoading}
            alt="video-share-loading"
            className="rotate"
          />
        </s.Container>
      )}
    </DefaultThumbnail>
  );
}

export default VideoShareThumbnail;
