import * as s from "./WidgetStyled";

import RGL, {
  WidthProvider,
  Layout,
  Responsive,
  ItemCallback,
} from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import * as mediaQuery from "components/MediaQuery";
import BigButton from "components/atoms/buttons/BigButton";
import AddLayout from "./AddLayout";
import EXPAND_HANDLE from "assets/icons/expand.svg";
import { AssignModalType } from "./WidgetContainer";
import {
  DashbaordAlertSendMessage,
  DashboarAlertLatest,
} from "api/interfaces/dashboardInterface.interface";
import CardItem, { CardItemName } from "./item/card/CardItem";
import TableItem from "./item/table/TableItem";
import { TableItemName, tableItemList } from "./item/table/TableItemList";
import { WidgetCardData } from "api/interfaces/widgetInterface.interface";
import { BiPlusCircle } from "react-icons/bi";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { LEVEL_TYPE } from "api/interfaces/commonInterface.interface";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

interface Props {
  cardLayout: Layout[] | undefined;
  chartLayout: Layout[] | undefined;
  isAddModal: boolean;
  isLoading: boolean;
  rowHeight: number;
  isAssignModal: AssignModalType;
  assignInfo: { [key: string]: string };
  selectedAlertItem: DashbaordAlertSendMessage;
  onAddLayout: (type?: string, newItem?: string[]) => void;
  onCardLayoutChange: (layout: Layout[]) => void;
  onTableLayoutChange: (layout: Layout[]) => void;
  onDeleteWidget: (key: TableItemName) => void;
  onAssignModal: (type: string) => void;
  onSelectItem: (item: DashboarAlertLatest) => void;
  cardData: WidgetCardData;
  onViewDetailCard: (key: CardItemName) => void;
  isDetailView: CardItemName | null;
  onDragStop: ItemCallback;
}

export default function WidgetPresenter(props: Props): JSX.Element {
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  return props.isLoading ||
    props.cardLayout === undefined ||
    props.chartLayout === undefined ? (
    <></>
  ) : (
    <>
      <s.Container>
        <mediaQuery.Default>
          <s.AddWrapper>
            <s.AddButon onClick={() => props.onAddLayout("open")}>
              <BiPlusCircle size={20} />
              Add New Widget
            </s.AddButon>
            {props.isAddModal && props.chartLayout !== undefined && (
              <AddLayout
                layouts={props.chartLayout}
                isAddModal={props.isAddModal}
                onAddLayout={props.onAddLayout}
              />
            )}
          </s.AddWrapper>
          {selectedAccount.accountLevel !== LEVEL_TYPE.EU &&
            props.cardLayout !== null && (
              <s.SummaryGrid>
                <ResponsiveReactGridLayout
                  autoSize
                  className="layout"
                  // cols={8}
                  // layout={props.layout}
                  breakpoints={{ lg: 1800, md: 1200, sm: 768, xs: 480, xxs: 0 }}
                  cols={{ lg: 4, md: 4, sm: 4, xs: 4, xxs: 2 }}
                  layouts={{
                    lg: props.cardLayout,
                    md: props.cardLayout,
                    sm: props.cardLayout,
                    xs: props.cardLayout,
                    xxs: props.cardLayout,
                  }}
                  preventCollision={false}
                  compactType={"horizontal"}
                  isResizable={false}
                  // onDrag={props.onDragStop}
                  onDragStop={props.onDragStop}
                  rowHeight={200}
                  // rowHeight={150}
                  margin={[20, 20]}
                  containerPadding={[0, 0]}
                  draggableCancel={".drag-cancel-content"}
                  // onLayoutChange={props.onCardLayoutChange}
                >
                  {props.cardLayout.map((l) => (
                    <s.SummaryGridItem key={l.i}>
                      <CardItem
                        name={l.i as CardItemName}
                        cardData={props.cardData[l.i as CardItemName]}
                        isDetailView={props.isDetailView === l.i}
                        onView={() =>
                          props.onViewDetailCard(l.i as CardItemName)
                        }
                      />
                    </s.SummaryGridItem>
                  ))}
                </ResponsiveReactGridLayout>
              </s.SummaryGrid>
            )}

          {props.chartLayout !== undefined && (
            <s.ChartGrid>
              <ResponsiveReactGridLayout
                autoSize
                className="layout"
                // cols={8}
                // layout={props.layout}
                breakpoints={{ lg: 1800, md: 1200, sm: 768, xs: 480, xxs: 0 }}
                cols={{ lg: 2, md: 2, sm: 2, xs: 2, xxs: 2 }}
                layouts={{
                  lg: props.chartLayout,
                  md: props.chartLayout,
                  sm: props.chartLayout,
                  xs: props.chartLayout,
                  xxs: props.chartLayout,
                }}
                resizeHandle={
                  <span className="react-resizable-handle react-resizable-handle-se">
                    <img src={EXPAND_HANDLE} alt="expand handle icon" />
                  </span>
                }
                onLayoutChange={props.onTableLayoutChange}
                // onDragStop={props.onLayoutChange}
                rowHeight={400}
                // rowHeight={150}
                margin={[20, 20]}
                containerPadding={[0, 0]}
                draggableCancel={".drag-cancel-content"}
              >
                {props.chartLayout !== undefined &&
                  props.chartLayout.map((l: Layout) => (
                    <s.SummaryGridItem key={l.i}>
                      <TableItem
                        key={`table-item-${l.i}`}
                        layout={l}
                        chartLayout={props.chartLayout ?? []}
                        onLayoutChange={props.onTableLayoutChange}
                        onDeleteWidget={props.onDeleteWidget}
                      />
                    </s.SummaryGridItem>
                  ))}
              </ResponsiveReactGridLayout>
            </s.ChartGrid>
          )}
        </mediaQuery.Default>
        <mediaQuery.Mobile>
          {props.cardLayout !== undefined &&
            props.cardLayout.map((cl) => (
              <s.WidgetMobileItem key={`card-item-${cl.i}`}>
                <CardItem
                  name={cl.i as CardItemName}
                  cardData={props.cardData[cl.i as CardItemName]}
                  isDetailView={props.isDetailView === cl.i}
                  onView={() => props.onViewDetailCard(cl.i as CardItemName)}
                />
              </s.WidgetMobileItem>
            ))}
          {props.chartLayout !== undefined &&
            props.chartLayout.map((cl) => (
              <s.WidgetMobileItem key={`chart-item-${cl.i}`}>
                <TableItem
                  layout={cl}
                  chartLayout={props.chartLayout ?? []}
                  onLayoutChange={props.onTableLayoutChange}
                  onDeleteWidget={props.onDeleteWidget}
                />
              </s.WidgetMobileItem>
            ))}
        </mediaQuery.Mobile>
      </s.Container>
    </>
  );
}
