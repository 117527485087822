import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import DefaultThumbnail from "../DefaultThumbnail";

export const Container = styled.div<{ isRecorderList?: boolean }>`
  background-color: ${COLORS.BLACK};
  color: ${COLORS.RED};
  font-size: ${calcRem(12)};
  font-weight: 500;
  ${FLEX.FlexCenterCenter};
  flex-direction: column;
  gap: 4px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;

  img {
    width: ${({ isRecorderList }) => (isRecorderList ? "30%" : "15%")};
  }
  .empty_thumb {
    /* height: 50%; */
    object-fit: contain;
  }

  .rotate {
    animation: rotate_image 6s linear infinite;
    transform-origin: 50% 50%;
  }

  @keyframes rotate_image {
    100% {
      transform: rotate(360deg);
    }
  }

  p {
    margin-top: 4px;
    font-size: ${calcRem(12)};
  }
`;

export const ImageBg = styled(DefaultThumbnail)`
  width: 100%;

  position: relative;

  img {
    object-fit: contain;
  }

  .black-filter {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0);
    position: absolute;
    left: 0;
    right: 0;
    /* z-index: -1; */
  }

  .vjs-loading-spinner {
    /* background-color: red; */
  }
`;
