import * as s from "styles/ModalCommonStyled";
import BigPopupTemplate from "components/templates/modal/big/BigPopupTemplate";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Input from "components/atoms/input/Input";
import TextArea from "components/atoms/input/TextArea";
import BigButton from "components/atoms/buttons/BigButton";
import MapUpload, {
  MapUploadImage,
} from "components/atoms/input/upload/map/MapUpload";
import AddressInput from "components/atoms/input/address/AddressInput";
import AddressMap from "components/atoms/input/address/AddressMap";
import type { Option } from "utils/options";
import { RecorderEditInfo } from "api/interfaces/recorderInterface.interface";
import { UPDATE_TYPE } from "./EditListContainer";
import SelectsCombo from "components/atoms/select/SelectCombo";
import { MultiValue } from "react-select";
import DateTimeSelect from "components/atoms/select/DateTimeSelect";
import Deactivate from "pages/users/add/deactivate";
import {
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import { ValidateWord } from "components/atoms/text/Text";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import RBACWrapper from "components/blocks/function/RBACWrapper";

interface Props {
  type: string;
  ruleType: string;
  onModal: (type?: string) => void;
  isLoaded: boolean;
  selected: google.maps.LatLngLiteral;
  onChangeLocationSelect: (
    address: google.maps.LatLngLiteral,
    addressString: string
  ) => void;
  onChangeRecordInfo: (
    type: UPDATE_TYPE,
    value: number | string | boolean | MultiValue<Option>
  ) => void;
  onChangeAddress: (e: any) => void;
  data: RecorderEditInfo;
  onSave: () => void;
  imgFile: any;
  saveImgFile: (e: any) => void;
  onDeleteImgFile: () => void;
  imgRef: any;
  groupData: Option[];
  selectGroup: Option[];
  mapRef: any;
  onMarkerChange: (
    address: google.maps.LatLngLiteral,
    addressString: string
  ) => void;
  onClickDeleteConfirm: () => void;
  onClickDelete: (result: boolean) => void;
  isDeleteConfirm: boolean;
  validate: Validate;
  validateMsg: ValidateMsg;
  selectedAccount: ProfileAccountInfo;
}
export function EditListPresenter(props: Props): JSX.Element {
  return (
    <BigPopupTemplate title="Edit Recorder" onModal={props.onModal}>
      <s.CloudContainer>
        <ModalInput label="Name" essential>
          <Input
            placeholder="Enter recorder name."
            value={props.data.name}
            disabled={true}
            onChange={(e) =>
              props.onChangeRecordInfo(UPDATE_TYPE.NAME, e.target.value)
            }
          />
        </ModalInput>

        <ModalInput label="Location" essential>
          {/* <Input placeholder="Enter this recorders address." /> */}
          <AddressInput
            isLoaded={props.isLoaded}
            defaultValue={props.data.location.location}
            value={props.data.location.location}
            onChange={props.onChangeAddress}
            onChangeLocationSelect={props.onChangeLocationSelect}
          />
          {props.validate.address && (
            <ValidateWord>{props.validateMsg.address}</ValidateWord>
          )}
          <s.Map>
            <AddressMap
              isLoaded={props.isLoaded}
              selected={props.selected}
              ref={props.mapRef}
              onMarkerChange={props.onMarkerChange}
            />
          </s.Map>
        </ModalInput>

        <s.EditTwopart>
          <div>
            <ModalInput label="Recorder Group">
              <SelectsCombo
                options={props.groupData}
                value={props.selectGroup}
                // isDisabled={props.isEdit}
                // onChange={(value: string) => {
                //   console.log("role select=" + value);
                //   onChangeUserInfo(UPDATE_TYPE.GROUP, value);
                // }}
                onChange={(value: MultiValue<Option>) => {
                  props.onChangeRecordInfo(UPDATE_TYPE.RECORDER_GROUP, value);
                  console.log({ value });
                }}
              />
            </ModalInput>

            <ModalInput label="Installation Date">
              <DateTimeSelect
                placeholder="Enter installation date"
                value={props.data.installedDate}
                onChange={(date: Date) =>
                  props.onChangeRecordInfo(
                    UPDATE_TYPE.INSTALL_DATE,
                    date.toISOString()
                  )
                }
              />
            </ModalInput>
          </div>

          <ModalInput label="Custom Thumbnail" half>
            <MapUpload
              onUpload={props.saveImgFile}
              onDelete={props.onDeleteImgFile}
              ref={props.imgRef}
              value={props.imgFile}
            />
            {props.imgFile !== "" && (
              <MapUploadImage>
                <img src={props.imgFile} alt="Recorder_Thumbnail" />
              </MapUploadImage>
            )}
          </ModalInput>
        </s.EditTwopart>
        <ModalInput label="Description">
          <TextArea
            placeholder="Enter description here."
            defaultValue={props.data.description}
            onChange={(e) =>
              props.onChangeRecordInfo(UPDATE_TYPE.DESC, e.target.value)
            }
          />
        </ModalInput>
        <s.ButtonWrapper>
          <RBACWrapper requiredPermissions={"manageRecordersAndGroups"}>
            <BigButton
              id="dw_recorder_delete"
              buttonType="warning"
              label="Delete"
              //disabled={true}
              disabled={props.data.isMerged}
              onClickBtn={props.onClickDeleteConfirm}
            />
          </RBACWrapper>
          <BigButton label="Save" onClickBtn={props.onSave} />
        </s.ButtonWrapper>
        {props.isDeleteConfirm && (
          <Deactivate
            onModal={props.onClickDelete}
            type="recorder"
            targetId={props.data.name}
          />
        )}
      </s.CloudContainer>
    </BigPopupTemplate>
  );
}
