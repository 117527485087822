import * as s from "../VideoShareStyled";
import { FaShareFromSquare } from "react-icons/fa6";
import { COLORS } from "styles/colors";
import { useNavigate } from "react-router-dom";
import { VideoShareResult } from "api/interfaces/videoshareInterface.interface";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import AddVS from "../view/add";
import {
  convertMsToTime,
  convertVideoStartMs,
  convertVideoStartMsVtt,
} from "utils/timeUtil";
import { isEmpty } from "lodash";
import { isNumber, matchHashTag } from "utils/functions";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import VideoShareThumbnail from "components/atoms/image/thumbnail/VideoShareThumbnail";
import RecorderTitle from "components/blocks/recorder/RecorderTitle";
import { useAppSelector } from "redux/hooks";
import { menuNavigation } from "utils/MenuUtil";
import * as mediaQuery from "components/MediaQuery";
import ModalTemplate from "components/templates/modal/ModalTemplate";

type Props = {
  index: number;
  item: VideoShareResult;
  onModelVisible: boolean;
  onClickShare: (videoId: string, isOpen: boolean) => void;
  selectedTag: string[];
  selectedAccount: ProfileAccountInfo;
};

export default function VideoSharePoster({
  item,
  index,
  onModelVisible,
  onClickShare,
  selectedTag,
  selectedAccount,
}: Props): JSX.Element {
  const navigate = useNavigate();
  const themeMode = useAppSelector((state) => state.theme.theme);
  const buttonRef = useRef<any>(null);
  const shareRef = useRef<any>(null);
  const [isShare, setIsShare] = useState<boolean>(false);
  const [isHoveredThumbnail, setIsHoveredThumbnail] = useState<boolean>(false);

  const onViewDetail = (item: any, e: any) => {
    // console.log(e.target, e.currentTarget);
    // if (e.target !== e.currentTarget) return;
    menuNavigation(selectedAccount, navigate, `/videoShare/${item.videoId}`);
    // navigate(`/videoshare/${item.videoId}`, {
    //   // 우선 navigate 하면서 item 모두 넘기지만 추후에는 id 만 넘긴 후 ViewVideoShare 에서 api 조회로 해야할듯
    //   // state: {
    //   //   videoId: item.videoId,
    //   // },
    // });
  };

  useEffect(() => {
    setIsShare(item.sharing);
  }, [item.sharing]);

  useEffect(() => {
    const onClick = (event: any) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        shareRef.current &&
        !shareRef.current.contains(event.target)
      ) {
        console.log("-----");
        setIsShare(false);
        onClickShare(item.videoId, false);
      }
    };

    document.body.addEventListener("click", onClick);

    return function cleanup() {
      window.removeEventListener("click", onClick);
    };
  }, [buttonRef, shareRef]);

  useEffect(() => {
    setIsShare(onModelVisible);
  }, [onModelVisible]);

  const containerRef = useRef<HTMLUListElement>(null);
  const [hiddenItemsCount, setHiddenItemsCount] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setHiddenItemsCount(countAndHideHiddenItems(containerRef.current));
      }
    };

    window.addEventListener("resize", handleResize);

    // Initial count on mount
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window, item]);

  const countAndHideHiddenItems = (container: HTMLUListElement): number => {
    const containerRect = container.getBoundingClientRect();
    const items = Array.from(container.children) as HTMLUListElement[];
    let hiddenCount = 0;

    items.forEach((item) => {
      const itemRect = item.getBoundingClientRect();
      const isHidden =
        itemRect.right > containerRect.right ||
        itemRect.bottom > containerRect.bottom ||
        itemRect.left < containerRect.left ||
        itemRect.top < containerRect.top;

      if (isHidden) {
        item.style.visibility = "hidden";
        hiddenCount++;
      } else {
        item.style.visibility = "visible";
      }
    });

    return hiddenCount;
  };

  const onModal = (videoId: string, on?: boolean) => {
    setIsShare(!isShare);
    onClickShare(videoId, on ? on : !isShare);
  };

  const onHoverThumbnail = (isHover: boolean) => {
    setIsHoveredThumbnail(isHover);
  };

  return (
    <>
      <s.VideoItem key={index}>
        <s.VideoImage
          uploadStatus={item.uploadStatus}
          onClick={(e) => {
            if (item.uploadStatus === "success") {
              onViewDetail(item, e);
            }
          }}
        >
          {!isHoveredThumbnail && (
            <>
              <s.ImageLabel>{item.cameraName}</s.ImageLabel>
              <s.ImageLabel time>
                {item.startTimeMs !== undefined
                  ? isNumber(item.startTimeMs)
                    ? convertVideoStartMs(
                        item.startTimeMs,
                        item.timezone,
                        selectedAccount
                      )
                    : convertVideoStartMsVtt(
                        item.startTimeMs,
                        item.timezone,
                        selectedAccount
                      )
                  : "--:--"}
              </s.ImageLabel>
            </>
          )}

          <VideoShareThumbnail
            videoUrl={item.url}
            thumbnailUrl={item.thumbnailUrl}
            isRecorderList={true}
            uploadStatus={item.uploadStatus}
            status={item.status}
            isShare={item.sharing}
            isHoveredThumbnail={isHoveredThumbnail}
            onHoverThumbnail={onHoverThumbnail}
            index={index}
          />
        </s.VideoImage>
        <s.VideoSection>
          <s.VideoTitle title={item.title}>
            <div className="video-title-inner">
              {/* <s.Status status={item.status}>
                {item.status.toUpperCase()}
              </s.Status> */}
              {item.title ? item.title : "Undefined"}
            </div>
            {/* <Text className="time" fontSize={14} bold color={`${COLORS.WHITE}50`}> */}
            {/* {item.timestamp} */}
            {/* {changeTimeStampFormat(item.timestamp, item.timezone)} */}
            {/* </Text> */}
            <RecorderTitle
              mergedSystemName={item.mergedSystemName}
              recorderName={item.recorderName}
              type={item.type}
            />
          </s.VideoTitle>
          <s.ShareWrapper>
            <button
              ref={buttonRef}
              onClick={(e) => {
                e.stopPropagation();
                onModal(item.videoId);
              }}
            >
              <FaShareFromSquare
                color={
                  onModelVisible
                    ? themeMode === "light"
                      ? COLORS.COMPANY
                      : COLORS.SELECTED
                    : themeMode === "light"
                    ? COLORS.BLACK
                    : COLORS.WHITE
                }
              />
              {/* <img
                className="share-img"
                src={onModelVisible ? SHARE_BLUE_ICON : SHARE_ICON}
                alt="share_popup_visible"
              /> */}
            </button>
            <mediaQuery.Default>
              {onModelVisible && (
                <div
                  onClick={(e) => {
                    // console.log("??");
                    e.stopPropagation();
                  }}
                >
                  <AddVS
                    small
                    ref={shareRef}
                    index={index}
                    videoId={item.videoId}
                    isShare={item.sharing}
                    onChangeModal={() => {
                      onModal(item.videoId, false);

                      return;
                    }}
                  />
                </div>
              )}
            </mediaQuery.Default>
          </s.ShareWrapper>
        </s.VideoSection>
        <s.TagBox>
          <s.FilterWrapper className="container" ref={containerRef}>
            {item.tags
              ?.sort((a, b) => {
                return selectedTag.includes(a.tag) ? -1 : 1;
              })
              .map((tag, idx) =>
                // console.log(tag.tag),
                tag.tag !== undefined && !isEmpty(tag.tag) ? (
                  <s.Hashtag
                    key={idx}
                    isFilter={matchHashTag(selectedTag, tag.tag)}
                  >
                    {`#${tag.tag}`}
                  </s.Hashtag>
                ) : (
                  <Fragment />
                )
              )}
          </s.FilterWrapper>
          <span className="hidden-count">
            {hiddenItemsCount > 0 && `+ ${hiddenItemsCount}`}
          </span>
        </s.TagBox>
      </s.VideoItem>
      <mediaQuery.Mobile>
        {onModelVisible && (
          <ModalTemplate center>
            <s.MobileModalInner>
              <AddVS
                small
                ref={shareRef}
                index={index}
                videoId={item.videoId}
                isShare={item.sharing}
                onChangeModal={() => {
                  onModal(item.videoId, false);
                  return;
                }}
              />
            </s.MobileModalInner>
          </ModalTemplate>
        )}
      </mediaQuery.Mobile>
    </>
  );
}
