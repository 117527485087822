import * as s from "../TableItemStyled";
import { useCallback, useEffect, useState } from "react";
import {
  ReportInventoryCameraRow,
  ReportInventoryRecorderRow,
} from "api/interfaces/reportInterface.interface";
import Type from "components/atoms/text/labels/Type";
import { convertType, formatBytesFromMB } from "utils/functions";
import RecorderTitle from "components/blocks/recorder/RecorderTitle";
import { useQuery } from "react-query";
import {
  BasePagination,
  LEVEL_TYPE,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { useIntl } from "react-intl";
import {
  commonOrgColumn,
  commonPaginationPerPage,
} from "pages/dashboard/dashboard/WidgetContainer";
import {
  postWidgetInventoryCamera,
  postWidgetInventoryRecorder,
} from "api/widgetAPI";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import ModalSubMenu from "components/blocks/modal/menu/ModalSubMenu";
import AccountSelects from "../AccountSelects";
import SearchInput from "components/atoms/input/search/SearchInput";
import {
  DashboardChartDetailTable,
  changeSortOrderString,
} from "components/atoms/table/AntdTable";
import { TableColumnsType, TablePaginationConfig } from "antd";
import { FilterValue, SortOrder, SorterResult } from "antd/es/table/interface";
import { getAccountTimeFormat } from "utils/timeUtil";
import { Layout } from "react-grid-layout";
import { TableItemName } from "../TableItemList";
import TableItemHeader from "../TableItemHeader";
import { Level } from "components/atoms/level/Level";
import DashboardTableTitle from "components/blocks/table/DashboardTableTitle";

interface Props {
  layout: Layout;
  chartLayout: Layout[];
  onDeleteWidget: (key: TableItemName) => void;
  onLayoutChange: (layout: Layout[]) => void;
}

export default function Inventory(props: Props): JSX.Element {
  const intl = useIntl();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const tabs = [{ label: "Recorders" }, { label: "Cameras" }];

  const [currentMenu, setCurrentMenu] = useState(0);
  const [accountIds, setAccountIds] = useState<string[]>([]);

  const recorderInitialQueryInfo = {
    total: 0,
    pageNum: 0,
    pageLimit: commonPaginationPerPage,
    keyword: "",
    sortType: "installedDate",
    sortDirection: SORT_DIRECTION.DESC,
  };

  const cameraInitialQueryInfo = {
    total: 0,
    pageNum: 0,
    pageLimit: commonPaginationPerPage,
    keyword: "",
    sortType: "mergedSystemName",
    sortDirection: SORT_DIRECTION.ASC,
  };
  const [recorderQueryInfo, setRecorderQueryInfo] = useState<BasePagination>({
    ...recorderInitialQueryInfo,
  });

  const [cameraQueryInfo, setCameraQueryInfo] = useState<BasePagination>({
    ...cameraInitialQueryInfo,
  });
  const [recorderData, setRecorderData] = useState<
    ReportInventoryRecorderRow[]
  >([]);

  const [cameraData, setCameraData] = useState<ReportInventoryCameraRow[]>([]);
  const onChangeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<ReportInventoryCameraRow>
      | SorterResult<ReportInventoryCameraRow>[]
    // extra: TableCurrentDataSource<any>
  ) => {
    // Ensure sorter is always an array
    const sorters = Array.isArray(sorter) ? sorter : [sorter];

    sorters.forEach((sort) => {
      if (sort && "columnKey" in sort && "order" in sort) {
        if (currentMenu === 0) {
          setRecorderQueryInfo((info: BasePagination) => {
            return {
              ...info,
              sortType: sort.columnKey as string,
              sortDirection: changeSortOrderString(sort.order as SortOrder),
            };
          });
        }
        if (currentMenu === 1) {
          setCameraQueryInfo((info: BasePagination) => {
            return {
              ...info,
              sortType: sort.columnKey as string,
              sortDirection: changeSortOrderString(sort.order as SortOrder),
            };
          });
        }
      }
    });
  };

  const recorderColumns: TableColumnsType<ReportInventoryRecorderRow> = [
    commonOrgColumn(selectedAccount),

    {
      title: "Type",
      key: "type",
      dataIndex: "type",
      width: 100,
      render: (value) => <Type type={convertType(value)} />,
      // filterMultiple: false,
      // filters: AlertRecorerTypeFilter.map((fil) => {
      //   return { text: fil.label, value: fil.value };
      // }),
    },
    {
      title: "Title",
      key: "name",
      dataIndex: "title",
      render: (value, row) => (
        <RecorderTitle
          mergedSystemName={row.mergedSystemName}
          recorderName={row.title}
          type={row.type}
        />
      ),
      sorter: true,
      sortDirections: ["ascend", "descend"],
      showSorterTooltip: false,
    },

    {
      title: "Cameras",
      key: "cameraCount",
      dataIndex: "cameraCount",
      align: "center",
      width: 90,
      // render: (value) => value,
    },
    {
      title: "Install Date",
      key: "installedDate",
      dataIndex: "installedDate",
      render: (value) => getAccountTimeFormat(value, selectedAccount, true),
      sorter: true,
      sortDirections: ["ascend", "descend"],
      showSorterTooltip: false,
      defaultSortOrder: "descend",
    },
    // {
    //   title: "Location",
    //   key: "location",
    //   dataIndex: "location",
    //   render: (value) => (value ? value : "Unknown"),
    //   responsive: ["lg"],
    // },
  ];

  const cameraColumns: TableColumnsType<ReportInventoryCameraRow> = [
    {
      title: "Organization",
      key: "accountName",
      dataIndex: "accountName",
      hidden: selectedAccount.accountLevel === LEVEL_TYPE.EU,
      render: (value, row) => (
        <s.ColumnText>
          <Level level={LEVEL_TYPE.EU} /> {value}
        </s.ColumnText>
      ),
      width: 120,
    },
    {
      title: "Recorder",
      key: "mergedSystemName",
      dataIndex: "recorderName",
      render: (value, row) => (
        <RecorderTitle
          mergedSystemName={row.mergedSystemName}
          recorderName={row.recorderName}
        />
      ),
      width: 150,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      showSorterTooltip: false,
      defaultSortOrder: "ascend",
    },
    {
      title: "Camera",
      key: "name",
      dataIndex: "name",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      showSorterTooltip: false,
    },
    {
      title: "Manufacturer",
      key: "manufacturer",
      dataIndex: "manufacturer",
    },
    {
      title: "Model",
      key: "model",
      dataIndex: "model",
    },
    // {
    //   title: "MAC address",
    //   key: "macAddr",
    //   dataIndex: "macAddr",
    //   render: (value) => (value ? value : "N/A"),
    // },
  ];
  const inventoryRecorderQuery = useQuery(
    [
      "inventoryRecorder",
      recorderQueryInfo,
      currentMenu,
      accountIds,
      selectedAccount,
    ],
    () =>
      postWidgetInventoryRecorder({
        accountId: selectedAccount.accountId,
        payload: {
          accountIds,
          listDto: recorderQueryInfo,
        },
      }),
    {
      retry: 0,
      enabled: currentMenu === 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result === undefined) {
          setRecorderData([]);
          setRecorderQueryInfo((info) => {
            return { ...info, total: 0 as number, pageNum: 0 };
          });
          return;
        }
        // console.log(res.result, "inventoryRecorder");
        setRecorderData(
          res.result.map((recorder: any) => {
            // console.log(recorder);
            return {
              ...recorder,
              location: recorder.location?.location,
            };
          })
        );
        setRecorderQueryInfo((info) => {
          return { ...info, total: res.page.total as number };
        });
      },
      onError: (e: any) => {
        setRecorderQueryInfo((info) => {
          return { ...info, total: 0 as number, pageNum: 0 };
        });
      },
    }
  );

  const inventoryCameraQuery = useQuery(
    ["inventoryCamera", cameraQueryInfo, currentMenu, accountIds],
    () =>
      postWidgetInventoryCamera({
        accountId: selectedAccount.accountId,
        payload: {
          accountIds,
          type: "",
          listDto: cameraQueryInfo,
        },
      }),
    {
      retry: 0,
      enabled: currentMenu === 1,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result === undefined) {
          setCameraData([]);
          setCameraQueryInfo((info) => {
            return { ...info, total: 0 as number, pageNum: 0 };
          });
          return;
        }
        // console.log(res.result, "inventoryCamera");
        setCameraData(
          res.result.map((camera: any) => {
            // 우선은 encoderIndex === 0 인것을 표시
            let resolution = camera.streams
              ?.filter((stream: any) => {
                if (stream.encoderIndex === 0) {
                  return stream.resolution;
                } else {
                  return null;
                }
                // else return;
              })
              .map(
                (stream: any) => stream.encoderIndex === 0 && stream.resolution
              );
            return {
              ...camera,
              resolution: resolution?.toString(),
              totalStorage:
                camera.storage !== undefined
                  ? camera.storage?.reduce(function (prev: any, next: any) {
                      return prev + next.total;
                    }, 0)
                  : 0,
            };
          })
        );
        setCameraQueryInfo((info) => {
          return { ...info, total: res.page.total as number };
        });
      },
      onError: (e: any) => {
        setCameraQueryInfo((info) => {
          return { ...info, total: 0 as number, pageNum: 0 };
        });
      },
    }
  );

  const onChangeTabMenu = (index: number) => {
    setCurrentMenu(index);
  };
  const onChangeAccount = (value: string[]) => {
    if (currentMenu === 0) {
      setRecorderQueryInfo((quer) => {
        return {
          ...quer,
          pageNum: 0,
        };
      });
    }
    if (currentMenu === 1) {
      setCameraQueryInfo((quer) => {
        return {
          ...quer,
          pageNum: 0,
        };
      });
    }

    setAccountIds(value);
  };

  const onClickSearch = (keyword: string) => {
    if (currentMenu === 0) {
      setRecorderQueryInfo((quer) => {
        return {
          ...recorderInitialQueryInfo,
          keyword,
        };
      });
    }
    if (currentMenu === 1) {
      setCameraQueryInfo((quer) => {
        return {
          ...cameraInitialQueryInfo,
          keyword,
        };
      });
    }
  };

  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      if (currentMenu === 0) {
        if (page !== recorderQueryInfo.pageNum + 1) {
          setRecorderQueryInfo((info) => {
            return { ...info, pageNum: (page - 1) as number };
          });
        }
      }
      if (currentMenu === 1) {
        if (page !== cameraQueryInfo.pageNum + 1) {
          setCameraQueryInfo((info) => {
            return { ...info, pageNum: (page - 1) as number };
          });
        }
      }
    },
    [currentMenu, recorderQueryInfo.pageNum, cameraQueryInfo.pageNum]
  );

  useEffect(() => {
    if (props.layout.h >= 2) {
      if (currentMenu === 0) {
        setRecorderQueryInfo((info) => {
          return {
            ...info,
            pageLimit: commonPaginationPerPage * props.layout.h,
          };
        });
      }

      if (currentMenu === 1) {
        setCameraQueryInfo((info) => {
          return {
            ...info,
            pageLimit: commonPaginationPerPage * props.layout.h,
          };
        });
      }
    } else {
      if (currentMenu === 0) {
        setRecorderQueryInfo((info) => {
          return {
            ...info,
            pageLimit: commonPaginationPerPage,
          };
        });
      }

      if (currentMenu === 1) {
        setCameraQueryInfo((info) => {
          return {
            ...info,
            pageLimit: commonPaginationPerPage,
          };
        });
      }
    }
  }, [props.layout.h]);

  const isError =
    (currentMenu === 0 && inventoryRecorderQuery.isError) ||
    (currentMenu === 1 && inventoryCameraQuery.isError);

  return (
    <>
      <TableItemHeader
        layout={props.layout}
        chartLayout={props.chartLayout}
        onDeleteWidget={props.onDeleteWidget}
        onLayoutChange={props.onLayoutChange}
        isError={isError}
      />
      <s.Contents className="drag-cancel-content">
        <s.ContentWrapper>
          <s.ContentHeader>
            <div>
              <ModalSubMenu
                menus={tabs}
                currentMenu={currentMenu}
                onChange={onChangeTabMenu}
              />
            </div>
            <div>
              <AccountSelects onChange={onChangeAccount} value={accountIds} />

              <SearchInput onClickSearch={onClickSearch} />
            </div>
          </s.ContentHeader>
          <s.ContentInner>
            {currentMenu === 0 && (
              <DashboardChartDetailTable
                dataSource={recorderData}
                columns={recorderColumns}
                queryInfo={recorderQueryInfo}
                onChangePage={onChangePage}
                onChange={onChangeTable}
              />
            )}
            {currentMenu === 1 && (
              <DashboardChartDetailTable
                dataSource={cameraData}
                columns={cameraColumns}
                queryInfo={cameraQueryInfo}
                onChangePage={onChangePage}
                onChange={onChangeTable}
              />
            )}
          </s.ContentInner>
        </s.ContentWrapper>
      </s.Contents>
    </>
  );
}
