import * as s from "../TableItemStyled";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { notify } from "components/atoms/notification/Notification";
import {
  BasePagination,
  LEVEL_TYPE,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { useIntl } from "react-intl";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import {
  DashboardChartDetailTable,
  changeSortOrderString,
} from "components/atoms/table/AntdTable";
import { TableColumnsType } from "antd";
import { LoginHistoryData } from "api/interfaces/widgetInterface.interface";
import { getWidgetLoginHistory } from "api/widgetAPI";
import { FaRegUser } from "react-icons/fa";
import { getAccountTimeFormat } from "utils/timeUtil";
import {
  commonOrgColumn,
  commonPaginationPerPage,
} from "pages/dashboard/dashboard/WidgetContainer";
import { Row } from "pages/users/UsersStyled";
import { isEmpty } from "lodash";
import { SortOrder, SorterResult } from "antd/es/table/interface";
import { Layout } from "react-grid-layout";
import { TableItemName } from "../TableItemList";
import TableItemHeader from "../TableItemHeader";
import { Level } from "components/atoms/level/Level";

interface Props {
  layout: Layout;
  chartLayout: Layout[];
  onDeleteWidget: (key: TableItemName) => void;
  onLayoutChange: (layout: Layout[]) => void;
}
export default function LoginHistory(props: Props): JSX.Element {
  const intl = useIntl();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const [data, setData] = useState<LoginHistoryData[]>([]);

  const [queryInfo, setQueryInfo] = useState<BasePagination>({
    total: 0,
    pageNum: 0,
    pageLimit: commonPaginationPerPage,
    keyword: "",
    sortType: "loginAt",
    sortDirection: SORT_DIRECTION.DESC,
  });

  const onChangeTable = (
    pagination: any,
    filters: any,
    sorter: SorterResult<LoginHistoryData> | SorterResult<LoginHistoryData>[]
    // extra: TableCurrentDataSource<any>
  ) => {
    // Ensure sorter is always an array
    const sorters = Array.isArray(sorter) ? sorter : [sorter];

    sorters.forEach((sort) => {
      if (sort && "columnKey" in sort && "order" in sort) {
        setQueryInfo((info: BasePagination) => {
          return {
            ...info,
            sortType: sort.columnKey as string,
            sortDirection: changeSortOrderString(sort.order as SortOrder),
          };
        });
      }
    });
  };

  const columns: TableColumnsType<LoginHistoryData> = [
    commonOrgColumn(selectedAccount),

    {
      title: "User",
      key: "userName",
      dataIndex: "userName",
      render: (value, row) => {
        let isAdmin = row.userGroups?.includes("Administrator");
        return (
          <Row isAdmin={isAdmin}>
            <div>
              <FaRegUser size={14} />
            </div>
            {row.isActivate ? value : isEmpty(value) ? "Invited User" : value}
          </Row>
        );
      },
      sorter: true,
      sortDirections: ["ascend", "descend"],
      showSorterTooltip: false,
    },
    {
      title: "Group",
      key: "userGroups",
      dataIndex: "userGroups",
      render: (value) => (value ? value.join(",") : ""),
    },

    {
      title: "Time",
      key: "loginAt",
      dataIndex: "loginAt",
      render: (value) => getAccountTimeFormat(value, selectedAccount),
      sorter: true,
      sortDirections: ["ascend", "descend"],
      showSorterTooltip: false,
      defaultSortOrder: "descend",
    },
  ];

  const getLoginHistory = useQuery(
    ["getWidgetLoginHistory", queryInfo, selectedAccount],
    () =>
      getWidgetLoginHistory({
        accountId: selectedAccount.accountId,
        payload: queryInfo,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.error !== 0 && res.result === undefined) {
          setData([]);
          setQueryInfo((info) => {
            return { ...info, total: 0 as number, pageNum: 0 };
          });
        } else {
          // console.log(res.result, "getWidgetLoginHistory");
          setData(res.result);
          setQueryInfo((info) => {
            return { ...info, total: res.page.total as number };
          });
        }
      },
      onError: (e: any) => {
        setQueryInfo((info) => {
          return { ...info, total: 0 as number, pageNum: 0 };
        });
      },
    }
  );

  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      console.log(page, queryInfo.pageNum, "onchangepage");
      if (page !== queryInfo.pageNum + 1) {
        setQueryInfo((info) => {
          return { ...info, pageNum: (page - 1) as number };
        });
      }
    },
    [queryInfo.pageNum]
  );

  useEffect(() => {
    if (props.layout.h >= 2) {
      setQueryInfo((info) => {
        return {
          ...info,
          pageLimit: commonPaginationPerPage * props.layout.h,
        };
      });
    } else {
      setQueryInfo((info) => {
        return {
          ...info,
          pageLimit: commonPaginationPerPage,
        };
      });
    }
  }, [props.layout.h]);

  return (
    <>
      <TableItemHeader
        layout={props.layout}
        chartLayout={props.chartLayout}
        onDeleteWidget={props.onDeleteWidget}
        onLayoutChange={props.onLayoutChange}
        isError={getLoginHistory.isError}
      />
      <s.Contents className="drag-cancel-content">
        <s.ContentWrapper>
          <s.ContentInner>
            <DashboardChartDetailTable
              dataSource={data}
              columns={columns}
              queryInfo={queryInfo}
              onChangePage={onChangePage}
              onChange={onChangeTable}
            />
          </s.ContentInner>
        </s.ContentWrapper>
      </s.Contents>
    </>
  );
}
