import { Fragment, useCallback, useEffect, useState } from "react";
import { RecordersPresenter } from "./ListPresenter";
import { Media, SortOrder, TableColumn } from "react-data-table-component";
import * as s from "../RecordersStyled";
import TableButton from "components/atoms/buttons/TableButton";
import Type from "components/atoms/text/labels/Type";
import ONLINE_ICON from "assets/icons/online.svg";
import OFFLINE_ICON from "assets/icons/offline.svg";
import TableTitle from "components/blocks/table/TableTitle";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Status from "components/atoms/text/labels/Status";
import TableColumnFilterTitle from "components/blocks/table/TableColumnFilterTitle";
import {
  BasePagination,
  CustomError,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { useAppSelector } from "redux/hooks";
import {
  LEVEL_TYPE,
  ProfileAccountInfo,
} from "api/interfaces/accountInterface.interface";
import { useQuery, useQueryClient } from "react-query";
import { deleteMergedSystem, getRecorderList, getRecorderListDetail } from "api/recorderAPI";
import { checkStatus, convertType } from "utils/functions";
import { StatusName, StatusValue } from "components/blocks/status/Status";
import RBACWrapper from "components/blocks/function/RBACWrapper";
import {
  LicenseCount,
  MergedRecorder,
  MergedRecorderListResponse,
  Recorder,
} from "api/interfaces/recorderInterface.interface";
import { LicenseRowType } from "api/interfaces/billing.interface";
import { isCheckPermission } from "utils/AccountUtil";
import { AlertRecorerTypeFilter } from "utils/AlertUtil";
import WebAppLink from "components/blocks/link/WebAppLink";
import { useIntl } from "react-intl";
import { isNull } from "lodash";
import { queryNavigate } from "utils/MenuUtil";
import { changeReponseRecorderListFormat } from "utils/RecorderUtil";
import { useMutation } from "react-query";
import { notify } from "components/atoms/notification/Notification";

export interface DataRow {
  id: string;
  recordId: string;
  cloudSystemId: string;
  type: string;
  brand?: string;
  title: string;
  status: string;
  //host: string;
  recorderCount: number;
  version: string;
  cameras: number;
  onlineStatusCount: number;
  mergedSystemId: string;
  licenses: LicenseRowType;
  createBy: string;
  createByEmail: string;
  enableEdit?: boolean;
  isMerged?:boolean;
  row: MergedRecorder | Recorder;
}

export interface MergedSystemInfo {
  selectedMergedSystemId: string;
  selectedMergedSystemName: string;
  selectedMergedSystemVersion: string;
  selectedCloudSystemId: string;
}

const statusInitValue = {
  totalTitle: "Total",
  totalValue: 0,
  items: [
    <>
      <s.Label>
        <img src={ONLINE_ICON} alt="online icon" />
        <StatusName>Online</StatusName>
      </s.Label>
      <StatusValue>0</StatusValue>
    </>,
    <>
      <s.Label>
        <img src={OFFLINE_ICON} alt="offline icon" />
        <StatusName>Offline</StatusName>
      </s.Label>
      <StatusValue>0</StatusValue>
    </>,
  ],
};

export function RecordersContainer(): JSX.Element {
  const intl = useIntl();
  const location = useLocation();

  const navigate = useNavigate();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const theme = useAppSelector((state) => state.theme);
  const queryClient = useQueryClient();
  const [recorder, setRecorder] = useState<DataRow[]>([]);
  const [recorderQueryInfo, setRecorderQueryInfo] = useState<BasePagination>({
    total: 0,
    pageNum: 0,
    pageLimit: 25,
    keyword: "",
    sortType: "mergedSystemName",
    sortDirection: SORT_DIRECTION.ASC,
    type: null,
  });
  const [listTotal, setListTotal] = useState<number>(0);

  const params = useParams();
  const mergedSystemId = params.mergedSystemId as string;
  const [mergedSystemInfo, setMergedSystemInfo] = useState<MergedSystemInfo>({
    selectedMergedSystemId: "",
    selectedMergedSystemName: "",
    selectedMergedSystemVersion: "",
    selectedCloudSystemId: "",
  });

  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);

  const onClickDeleteConfirm = useCallback(() => {
    setIsDeleteConfirm(true);
  }, []);

  // const selectedMergedSystemId = location?.state?.mergedSystemId as string;
  // const selectedMergedSystemName = location?.state?.mergedSystemName as string;
  // const selectedMergedSystemVersion = location?.state?.version as string;
  // const selectedCloudSystemId = location?.state?.cloudSystemId as string;

  const [recorderDetailQueryInfo, setRecorderDetailQueryInfo] =
    useState<BasePagination>({
      total: 0,
      pageNum: 0,
      pageLimit: 25,
      keyword: "",
      sortType: "name",
      sortDirection: SORT_DIRECTION.ASC,
    });

  const [status, setStatus] = useState(statusInitValue);
  const [licenseCount, setLicenseCount] = useState<LicenseCount>({
    totalCameraCount: 0,
    totalLicensedCameraCount: 0,
  });

  const [isAddModal, setIsAddModal] = useState<boolean>(false);
  const [isEditModal, setIsEditModal] = useState<boolean>(false);
  const [isLicenseModal, setIsLicenseModal] = useState<boolean>(false);
  const [isRemoveLicenseModal, setIsRemoveLicenseModal] =
    useState<boolean>(false);
  const [selectRow, setSelectRow] = useState<DataRow>();
  const isDetail = location.pathname.includes("/detail");

  const [isBubble, setIsBubble] = useState<boolean>(false);

  const [isRowClickEditable, setIsRowClickEditable] = useState<boolean>(
    isCheckPermission("manageRecordersAndGroups", selectedAccount)
  );
  useEffect(() => {
    if (selectedAccount) {
      setIsRowClickEditable(
        isCheckPermission("manageRecordersAndGroups", selectedAccount)
      );
    }
  }, [selectedAccount]);

  useEffect(() => {
    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      if (!isDetail) {
        setRecorderQueryInfo((info) => {
          return {
            ...info,
            pageNum: isNull(changeParam.get("pageNum"))
              ? 0
              : Number(changeParam.get("pageNum")),
            pageLimit: isNull(changeParam.get("pageLimit"))
              ? 25
              : Number(changeParam.get("pageLimit")),
            keyword: isNull(changeParam.get("keyword"))
              ? ""
              : (changeParam.get("keyword") as string),
            sortType: isNull(changeParam.get("sortType"))
              ? "mergedSystemName"
              : (changeParam.get("sortType") as string),
            sortDirection: isNull(changeParam.get("sortDirection"))
              ? SORT_DIRECTION.ASC
              : (changeParam.get("sortDirection") as SORT_DIRECTION),
            type: isNull(changeParam.get("type"))
              ? null
              : (changeParam.get("type") as string),
          };
        });
      } else {
        setRecorderDetailQueryInfo((info) => {
          return {
            ...info,
            pageNum: isNull(changeParam.get("pageNum"))
              ? 0
              : Number(changeParam.get("pageNum")),
            pageLimit: isNull(changeParam.get("pageLimit"))
              ? 25
              : Number(changeParam.get("pageLimit")),
            keyword: isNull(changeParam.get("keyword"))
              ? ""
              : (changeParam.get("keyword") as string),
            sortType: isNull(changeParam.get("sortType"))
              ? "name"
              : (changeParam.get("sortType") as string),
            sortDirection: isNull(changeParam.get("sortDirection"))
              ? SORT_DIRECTION.ASC
              : (changeParam.get("sortDirection") as SORT_DIRECTION),
          };
        });
      }
    } else {
      if (!isDetail) {
        setRecorderQueryInfo((info) => {
          return {
            ...info,
            pageNum: 0,
            pageLimit: 25,
            keyword: "",
            sortType: "mergedSystemName",
            sortDirection: SORT_DIRECTION.ASC,
            type: null,
          };
        });
      } else {
        setRecorderDetailQueryInfo((info) => {
          return {
            ...info,
            pageNum: 0,
            pageLimit: 25,
            keyword: "",
            sortType: "name",
            sortDirection: SORT_DIRECTION.ASC,
          };
        });
      }
    }
  }, [location]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const recorderQuery = useQuery(
    ["recorder", recorderQueryInfo, selectedAccount],
    () =>
      getRecorderList({
        payload: recorderQueryInfo,
        accountId: selectedAccount.accountId,
      }),
    {
      enabled: !isDetail,
      refetchInterval: 10000,
      refetchOnWindowFocus: false,
      retry: 0,
      onSuccess: (res: MergedRecorderListResponse) => {
        if (res.error !== 0 || res.result === undefined) {
          setRecorder([]);
          setListTotal(0);
          setLicenseCount({
            totalCameraCount: 0,
            totalLicensedCameraCount: 0,
          });
          setStatus({
            totalTitle: "Total",
            totalValue: 0,
            items: [
              <>
                <s.Label>
                  <img src={ONLINE_ICON} alt="online icon" width={16} />
                  <StatusName>Online</StatusName>
                </s.Label>
                <StatusValue>{0}</StatusValue>
              </>,
              <>
                <s.Label>
                  <img src={OFFLINE_ICON} alt="offline icon" width={16} />
                  <StatusName>Offline</StatusName>
                </s.Label>
                <StatusValue>{0}</StatusValue>
              </>,
            ],
          });
          return;
        }

        setLicenseCount({
          totalCameraCount: res.result.licenseCount.totalCameraCount,
          totalLicensedCameraCount:
            res.result.licenseCount.totalLicensedCameraCount,
        });
        if (
          res.result.summary.totalRecorderCount !== undefined &&
          res.result.summary.totalRecorderCount !== 0 &&
          res.result.mergedSystemList.length !== 0
        ) {
          setRecorder(
            res.result.mergedSystemList.map((recorder: MergedRecorder) => {
              // console.log(recorder);
              return {
                id: recorder.mergedSystemId,
                recordId:
                  recorder.recorderCount === 1
                    ? recorder.recorders[0]?.recorderId
                    : recorder.mergedSystemId,
                mergedSystemId: recorder.mergedSystemId,
                type: recorder.type,
                title: recorder.mergedSystemName,
                status:
                  recorder.recorderCount !== undefined &&
                  recorder.onlineStatusCount !== undefined
                    ? checkStatus(
                        recorder.recorderCount,
                        recorder.onlineStatusCount
                      )
                    : "offline",
                createBy: recorder.createBy,
                brand: recorder.recorders[0]?.brand,
                createByEmail: recorder.createByEmail,
                //host: recorder.installer,
                onlineStatusCount: recorder.onlineStatusCount,
                recorderCount: recorder.recorderCount,
                cloudSystemId: recorder.cloudSystemId,
                version: recorder.firmwareVersion,
                cameras: recorder.cameraCount,
                enableEdit: recorder.enableEdit,
                licenses: {
                  count: 0,
                  status: "PREPARING",
                },
                row: recorder,
              };
            })
          );

          setListTotal(res.page.total);

          setStatus({
            totalTitle: "Total",
            totalValue: res.result.summary.totalRecorderCount,
            items: [
              <>
                <s.Label>
                  <img src={ONLINE_ICON} alt="online icon" width={16} />
                  <StatusName>Online</StatusName>
                </s.Label>
                <StatusValue>
                  {res.result.summary.onlineStatusCount}
                </StatusValue>
              </>,
              <>
                <s.Label>
                  <img src={OFFLINE_ICON} alt="offline icon" width={16} />
                  <StatusName>Offline</StatusName>
                </s.Label>
                <StatusValue>
                  {res.result.summary.totalRecorderCount -
                    res.result.summary.onlineStatusCount}
                </StatusValue>
              </>,
            ],
          });
        } else {
          setRecorder([]);
          setListTotal(0);
          setStatus({
            totalTitle: "Total",
            totalValue: 0,
            items: [
              <>
                <s.Label>
                  <img src={ONLINE_ICON} alt="online icon" width={16} />
                  <StatusName>Online</StatusName>
                </s.Label>
                <StatusValue>{0}</StatusValue>
              </>,
              <>
                <s.Label>
                  <img src={OFFLINE_ICON} alt="offline icon" width={16} />
                  <StatusName>Offline</StatusName>
                </s.Label>
                <StatusValue>{0}</StatusValue>
              </>,
            ],
          });
        }
      },
      onError: (e: CustomError) => {
        setListTotal(0);
        setRecorder([]);
        setStatus(statusInitValue);
      },
      onSettled: (e: any) => {
        setPending(false);
      },
    }
  );

  const onChangeReportType = useCallback(
    (value: string | undefined) => {
      if (value !== undefined) {
        if (value === "all") {
          if (recorderQueryInfo.type !== null) {
            setRecorderQueryInfo((info) => {
              return { ...info, type: null, pageNum: 0 };
            });
          } else {
            recorderQuery.refetch();
          }
        } else {
          if (recorderQueryInfo.type !== value) {
            setRecorderQueryInfo((info) => {
              return { ...info, type: value as string, pageNum: 0 };
            });
          } else {
            recorderQuery.refetch();
          }
        }
      }
    },
    [recorderQuery, recorderQueryInfo.type]
  );
  //temp columns
  const columns: TableColumn<DataRow>[] = isDetail
    ? [
        {
          name: "Type",
          grow: 1,
          maxWidth: "110px",
          // center: true,
          cell: (row) => <Type type={convertType(row.type)} />,
        },
        {
          id: "title",
          name: "Title",
          sortable: true,
          sortField: "name",
          compact: true,
          grow: 2,
          cell: (row) => (
            <s.Row>
              <Status status={row.status} />
              {row.title}
            </s.Row>
          ),
        },
        {
          name: "host",
          sortable: true,
          sortField: "createBy",
          grow: 2,
          cell: (row) =>
            row.createBy !== undefined
              ? `${row.createBy} (${row.createByEmail})`
              : "",
        },
        {
          name: "Cameras",
          grow: 1,
          selector: (row) => row.cameras,
          center: true,
          hide: Media.SM,
        },
        // edit 버튼
        {
          name: "",
          grow: 1,
          cell: (row) => (
            <RBACWrapper requiredPermissions="manageRecordersAndGroups">
              <s.Row>
                <span className="row-click-button">
                  <TableButton
                    label="Edit"
                    onClickBtn={() => onModal("edit", row)}
                  />
                </span>
              </s.Row>
            </RBACWrapper>
          ),
          right: true,
        },
      ]
    : [
        {
          name: (
            <TableColumnFilterTitle
              title="Type"
              filters={AlertRecorerTypeFilter}
              onClick={onChangeReportType}
            />
          ),
          // sortable: true, // 정렬 활성화,
          grow: 1,
          maxWidth: "110px",
          // center: true,
          cell: (row) => <Type type={convertType(row.type)} />,
        },
        {
          id: "title",
          name: "Title",
          // compact: true,
          sortable: true,
          sortField: "mergedSystemName",
          minWidth: "130px",
          maxWidth: "400px",
          ignoreRowClick: true,
          grow: 4,
          cell: (row) =>
            row.recorderCount !== undefined && row.recorderCount > 0 ? (
              <TableTitle
                row={row}
                isRowClickEditable={isRowClickEditable}
                onCellClick={() => onClickEdit(row)}
                onUnderlineClickWithVersion={(
                  systemName,
                  mergedSystemId,
                  version
                ) => {
                  setSelectRow(row);
                  onUnderlineClick(
                    systemName,
                    mergedSystemId,
                    version,
                    row.cloudSystemId
                  );
                }}
              />
            ) : (
              <Fragment />
            ),
        },
        {
          name: "",
          // grow: 1,
          ignoreRowClick: true,
          cell: (row) => (
            <s.Row>
              <WebAppLink
                type={row.type}
                brand={row.brand}
                accountId={selectedAccount.accountId}
                recorderId={row.recordId}
                mergedSystemId={row.mergedSystemId}
                cloudSystemId={row.cloudSystemId}
              />
            </s.Row>
          ),
          right: true,
        },
        {
          name: "Host",
          grow: 2,
          sortable: true,
          sortField: "createBy",
          selector: (row) =>
            row.createBy !== undefined
              ? `${row.createBy} (${row.createByEmail})`
              : "",
          hide: Media.SM,
        },
        {
          name: "Cameras",
          grow: 1,
          maxWidth: "50px",
          selector: (row) => row.cameras,
          center: true,
          hide: Media.SM,
        },
        {
          name: "",
          grow: 1,
          cell: (row) =>
            row.recorderCount === 1 && row.enableEdit ? (
              <RBACWrapper requiredPermissions="manageRecordersAndGroups">
                <s.Row>
                  <span className="row-click-button">
                    <TableButton
                      label="Edit"
                      onClickBtn={() => onClickEdit(row)}
                    />
                  </span>
                </s.Row>
              </RBACWrapper>
            ) : (
              //<Fragment/>
              row.recorderCount > 1 ? (
                <RBACWrapper requiredPermissions="manageRecordersAndGroups">
                  <s.Row>
                    <span className="row-click-button">
                      <TableButton
                        label="Delete"
                        buttonType="warning"
                        onClickBtn={() => onClickRemove(row)}
                      />
                    </span>
                  </s.Row>
                </RBACWrapper>
              ) :
              <Fragment/>
            ),
          right: true,
        },
      ];

  const mobileColumns: TableColumn<DataRow>[] = isDetail
    ? [
        {
          name: "Type",
          grow: 1,
          minWidth: "110px",
          // center: true,
          cell: (row) => <Type type={convertType(row.type)} />,
        },
        {
          id: "title",
          name: "Title",
          sortable: true,
          sortField: "name",
          compact: true,
          grow: 2,
          cell: (row) => (
            <s.Row>
              <Status status={row.status} />
              {row.title}
            </s.Row>
          ),
        },

        // edit 버튼
        {
          name: "",
          // grow: 1,
          cell: (row) => (
            <RBACWrapper requiredPermissions="manageRecordersAndGroups">
              <s.Row>
                <span className="row-click-button">
                  <TableButton
                    label="Edit"
                    onClickBtn={() => onModal("edit", row)}
                  />
                </span>
              </s.Row>
            </RBACWrapper>
          ),
          right: true,
        },
      ]
    : [
        {
          name: (
            <TableColumnFilterTitle
              title="Type"
              filters={AlertRecorerTypeFilter}
              onClick={onChangeReportType}
            />
          ),
          // sortable: true, // 정렬 활성화,
          grow: 1,
          // minWidth: "0px",
          minWidth: "110px",
          // center: true,
          cell: (row) => <Type type={convertType(row.type)} />,
        },
        {
          id: "title",
          name: "Title",
          compact: true,
          sortable: true,
          sortField: "mergedSystemName",
          ignoreRowClick: false,
          grow: 2,
          minWidth: "0px",
          cell: (row) =>
            row.recorderCount !== undefined && row.recorderCount > 0 ? (
              <TableTitle
                row={row}
                isRowClickEditable={isRowClickEditable}
                onCellClick={() => onClickEdit(row)}
                onUnderlineClickWithVersion={(
                  systemName,
                  mergedSystemId,
                  version
                ) => {
                  setSelectRow(row);
                  onUnderlineClick(
                    systemName,
                    mergedSystemId,
                    version,
                    row.cloudSystemId
                  );
                }}
              />
            ) : (
              <Fragment />
            ),
        },
        {
          name: "",
          grow: 1,
          ignoreRowClick: false,
          cell: (row) => (
            <s.Row>
              <WebAppLink
                type={row.type}
                accountId={selectedAccount.accountId}
                recorderId={row.recordId}
                mergedSystemId={row.mergedSystemId}
                cloudSystemId={row.cloudSystemId}
              />
            </s.Row>
          ),
          right: true,
        },

        // edit 버튼
        {
          name: "",
          // grow: 1,
          cell: (row) =>
            row.recorderCount === 1 && row.enableEdit ? (
              <RBACWrapper requiredPermissions="manageRecordersAndGroups">
                <s.Row>
                  <span className="row-click-button">
                    <TableButton
                      label="Edit"
                      onClickBtn={() => onClickEdit(row)}
                    />
                  </span>
                </s.Row>
              </RBACWrapper>
            ) : (
              <></>
            ),
          right: true,
        },
      ];

  const onModal = (type: string, row?: DataRow) => {
    if (type === "add") {
      if (isAddModal) {
        queryClient.invalidateQueries("recorder");
      }
      setIsAddModal(!isAddModal);
    }
    if (type === "edit") {
      setSelectRow(row);
      setIsEditModal(!isEditModal);
    }
    if (type === "license") {
      setIsLicenseModal(!isLicenseModal);
    }
    if (type === "remove") {
      setIsRemoveLicenseModal(!isRemoveLicenseModal);
    }
  };

  //TODO  Recorder명을 클릭 시 Recport health 페이지 이동.. report health rule id가 필요함.
  const onCellClick = (row: DataRow) => {
    // navigate("/reports/health/detail", {
    //   state: {
    //     row: row,
    //   },
    // });
  };

  const onUnderlineClick = (
    systemName: string,
    mergedSystemId: string,
    version: string,
    cloudSystemId: string
  ) => {
    // navigate("/recorders/list/detail", {
    //   state: {
    //     mergedSystemId: mergedSystemId,
    //     mergedSystemName: systemName,
    //     version: version,
    //     cloudSystemId:cloudSystemId
    //   },
    // });
  };

  const onClickRemove = (row: DataRow) => {
    setSelectRow(row);
    setIsDeleteConfirm(true);
  }

  const onClickEdit = (row: DataRow) => {
    if (
      !isDetail &&
      row.recorderCount === 1 &&
      isCheckPermission("manageRecordersAndGroups", selectedAccount)
    ) {
      onModal("edit", row);
    } else if (!isDetail && row.recorderCount > 1) {
      navigate({
        pathname: `/recorders/list/detail/${row.mergedSystemId}`,
        search: `?${createSearchParams({
          account: selectedAccount.accountId,
        })}`,
      });
    } else if (
      isDetail &&
      isCheckPermission("manageRecordersAndGroups", selectedAccount)
    ) {
      onModal("edit", row);
    }
  };

  const [pending, setPending] = useState<boolean>(true);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const recorderDetailQuery = useQuery(
    ["recorderDetail", recorderDetailQueryInfo, mergedSystemId],
    () =>
      getRecorderListDetail({
        payload: recorderDetailQueryInfo,
        accountId: selectedAccount.accountId,
        mergedSystemId: mergedSystemId,
      }),
    {
      enabled: isDetail,
      retry: 0,
      onSuccess: (res: any) => {
        if (res.result === undefined || res.error !== 0) {
          return;
        }
        setMergedSystemInfo({
          selectedMergedSystemId: res.result.mergedSystemId,
          selectedMergedSystemName: res.result.mergedSystemName,
          selectedMergedSystemVersion: res.result.firmwareVersion,
          selectedCloudSystemId: res.result.cloudSystemId,
        });

        setRecorder(
          res.result.recorders.map((recorder: any) => {
            // console.log(recorder);
            return {
              id: recorder.recorderId,
              recordId: recorder.recorderId,
              cloudSystemId: res.result.cloudSystemId,
              mergedSystemId: res.result.mergedSystemId,
              type: recorder.type,
              title: recorder.name,
              status: recorder.onlineStatue ? "online" : "offline",
              host: recorder.installer,
              onlineStatusCount: recorder.onlineStatusCount,
              recorderCount: recorder.recorderCount,
              spectrumRecoders: recorder.maxCameras,
              version: recorder.firmwareVersion,
              cameras: recorder.cameraCount,
              createBy: recorder.createBy,
              createByEmail: recorder.createByEmail,
              isMerged : true,
              licenses: {
                count: 0,
                status: "PREPARING",
              },
              row: recorder,
            };
          })
        );

        setListTotal(res.page.total);

        setStatus({
          totalTitle: "Total",
          totalValue: res.page.total,
          items: [
            <>
              <s.Label>
                <img src={ONLINE_ICON} alt="online icon" width={16} />
                <StatusName>Online</StatusName>
              </s.Label>
              <StatusValue>{res.result.onlineStatusCount}</StatusValue>
            </>,
            <>
              <s.Label>
                <img src={OFFLINE_ICON} alt="offline icon" width={16} />
                <StatusName>Offline</StatusName>
              </s.Label>
              <StatusValue>
                {res.page.total - res.result.onlineStatusCount}
              </StatusValue>
            </>,
          ],
        });
      },
      onError: (e: any) => {
        setListTotal(0);
        setMergedSystemInfo({
          selectedMergedSystemId: "",
          selectedMergedSystemName: "",
          selectedMergedSystemVersion: "",
          selectedCloudSystemId: "",
        });
        setRecorder([]);
        setStatus(statusInitValue);
      },
      onSettled: (e: any) => {
        setPending(false);
      },
    }
  );

  const onChangePage = (page: number, totalRows: number) => {
    if (!isDetail) {
      if (page !== recorderQueryInfo.pageNum + 1) {
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: recorderQueryInfo.pageLimit,
            pageNum: page - 1,
            keyword: recorderQueryInfo.keyword as string,
            sortType: recorderQueryInfo.sortType,
            sortDirection: recorderQueryInfo.sortDirection as SORT_DIRECTION,
          },
          selectedAccount
        );
        setPending(true);
        // setRecorderQueryInfo((info) => {
        //   return { ...info, pageNum: (page - 1) as number };
        // });
      }
    } else {
      if (page !== recorderDetailQueryInfo.pageNum + 1) {
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: recorderDetailQueryInfo.pageLimit,
            pageNum: page - 1,
            keyword: recorderDetailQueryInfo.keyword as string,
            sortType: recorderDetailQueryInfo.sortType,
            sortDirection:
              recorderDetailQueryInfo.sortDirection as SORT_DIRECTION,
          },
          selectedAccount
        );
        setPending(true);
        // setRecorderDetailQueryInfo((info) => {
        //   return { ...info, pageNum: (page - 1) as number };
        // });
      }
    }
  };

  const onClickSearch = useCallback(
    (keyword: string) => {
      if (isDetail) {
        if (recorderDetailQueryInfo.keyword !== keyword) {
          queryNavigate(
            navigate,
            location.pathname,
            {
              pageLimit: recorderDetailQueryInfo.pageLimit,
              pageNum: 0,
              keyword: keyword,
              sortType: recorderDetailQueryInfo.sortType,
              sortDirection:
                recorderDetailQueryInfo.sortDirection as SORT_DIRECTION,
            },
            selectedAccount
          );
        } else {
          recorderDetailQuery.refetch();
        }
        setPending(true);
        // setRecorderDetailQueryInfo((query) => {
        //   return {
        //     ...query,
        //     keyword,
        //   };
        // });
        // queryClient.invalidateQueries("recorderDetail");
      } else {
        if (recorderQueryInfo.keyword !== keyword) {
          queryNavigate(
            navigate,
            location.pathname,
            {
              pageLimit: recorderQueryInfo.pageLimit,
              pageNum: 0,
              keyword: keyword,
              sortType: recorderQueryInfo.sortType,
              sortDirection: recorderQueryInfo.sortDirection as SORT_DIRECTION,
            },
            selectedAccount
          );
        } else {
          recorderQuery.refetch();
        }
        setPending(true);
        // setRecorderQueryInfo((query) => {
        //   return {
        //     ...query,
        //     keyword,
        //   };
        // });
        // queryClient.invalidateQueries("recorder");
      }
    },
    [
      isDetail,
      location.pathname,
      navigate,
      recorderDetailQuery,
      recorderDetailQueryInfo.keyword,
      recorderDetailQueryInfo.pageLimit,
      recorderDetailQueryInfo.sortDirection,
      recorderDetailQueryInfo.sortType,
      recorderQuery,
      recorderQueryInfo.keyword,
      recorderQueryInfo.pageLimit,
      recorderQueryInfo.sortDirection,
      recorderQueryInfo.sortType,
      selectedAccount,
    ]
  );

  const handleSort = async (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField !== undefined) {
      isDetail
        ? setRecorderDetailQueryInfo((info) => {
            return {
              ...info,
              sortType: selectedColumn.sortField as string,
              sortDirection: sortDirection.toUpperCase() as SORT_DIRECTION,
            };
          })
        : setRecorderQueryInfo((info) => {
            return {
              ...info,
              sortType: selectedColumn.sortField as string,
              sortDirection: sortDirection.toUpperCase() as SORT_DIRECTION,
            };
          });
      //setPending(true);
    }
  };

  const mutationDeleteSystem = useMutation(deleteMergedSystem, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.recorder.notify.deRegistration.success",
          defaultMessage: "System deleted successfully.",
        })
      );
      queryClient.invalidateQueries("recorder");
    },
    onError: () => {
      notify(
        "error",
        intl.formatMessage({
          id: "label.recorder.notify.deRegistration.fail",
          defaultMessage: "Failed to delete system.",
        })
      );
    },
    onSettled: () =>{
      setIsDeleteConfirm(false);
    }
  });
  const onClickDelete = useCallback(
    (result: boolean) => {
      setIsDeleteConfirm(false);
      if (result) {
        console.log(selectRow?.mergedSystemId);
        mutationDeleteSystem.mutate({
          accountId: selectedAccount.accountId,
          mergedSystemId: selectRow?.mergedSystemId,
        });
      }
    },
    [mutationDeleteSystem, selectedAccount.accountId, selectRow?.mergedSystemId]
  );

  return (
    <RecordersPresenter
      onModal={onModal}
      selectRow={selectRow as DataRow}
      data={recorder}
      isAddModal={isAddModal}
      isEditModal={isEditModal}
      isLicenseModal={isLicenseModal}
      isRemoveLicenseModal={isRemoveLicenseModal}
      isBubble={isBubble}
      licenseCount={licenseCount}
      setIsBubble={setIsBubble}
      columns={columns}
      mobileColumns={mobileColumns}
      status={status}
      isDetail={isDetail}
      paginationPerPage={recorderQueryInfo.pageLimit}
      paginationInfo={isDetail ? recorderDetailQueryInfo : recorderQueryInfo}
      totalRows={listTotal}
      onChangePage={onChangePage}
      onClickSearch={onClickSearch}
      onClickEdit={onClickEdit}
      handleSort={handleSort}
      selectedMergedSystemId={mergedSystemInfo.selectedMergedSystemId}
      selectedMergedSystemName={mergedSystemInfo.selectedMergedSystemName}
      selectedMergedSystemVersion={mergedSystemInfo.selectedMergedSystemVersion}
      selectedCloudSystemId={mergedSystemInfo.selectedCloudSystemId}
      // onClickWebLink={OnClickWebLink}
      // onClickAppLink={OnClickAppLink}
      isRowClickEditable={isRowClickEditable}
      initialPending={pending}
      selectedAccount={selectedAccount}
      isDeleteConfirm={isDeleteConfirm}
      onClickDelete={onClickDelete}

    />
  );
}
